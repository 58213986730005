<template>
  <div class="ly_wrapper">
    <router-view />
  </div>
</template>

<script>
import "@/assets/js/invite/common";

export default {
  name: "InviteLayout",
};
</script>

<style scoped>
@import "~@/assets/css/invite/common.css";
@import "~@/assets/css/invite/content.css";
@import "~@/assets/css/invite/normalize.css";
@import "~@/assets/css/invite/custom.css";
</style>
